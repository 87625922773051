<template>
	<vs-prompt
		class="dialog-broadcast"
		:title="printTitlePrompt()"
		@accept="submitData()"
		:accept-text="$t('Save Changes')"
		:cancel-text="$t('Cancel')"
		:active.sync="isSidebarActiveLocal">
		<div class="con-exemple-prompt broadcast-container">
			<div class="p-6">
				<!-- Image -->
				<template>
					<!-- Image Container -->
					<vs-row vs-type="flex" vs-justify="center">
            <div class="thumbnail-profile-sidebar">
              <input type="file" class="hidden" ref="updateImgProfile" @change="selectImage" accept="image/*">
              <div v-if="isShowAvatar" class="text-center" @click="$refs.updateImgProfile.click()">
                <vs-avatar  v-if="!dataImg" size="100px" class="text-6xl"  :color="getColor(dataName)" :text="getInitial(dataName)" />
                <vs-avatar v-if="dataImg" size="70px" class="text-6xl avatar-img" :src="dataImg" />

              </div>
              <div class="modify-img flex mt-2 justify-center">
                <vs-button color="secondary" type="border" class="p-1 px-4 "
                  @click="$refs.updateImgProfile.click()">
                  <div class="flex items-center pt-1">
                    <svg class="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24">
                      <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" /></svg>
                    {{$t('Upload Avatar')}}
                  </div>
                </vs-button>
                <vs-button class="ml-2 btn-icon" color="secondary" type="border" icon="delete"
                  @click="deleteAvatar()"></vs-button>
              </div>
            </div>
					</vs-row>
				</template>
        <!-- Email -->
					<!-- <div class="vx-col sm:w-full w-full mb-2">
						<div class="mt-8">
							<vs-input v-filter-input-email
								class="w-full"
								name="item-email"
								:label-placeholder="$t('Email')"
								v-model="dataEmail"
								v-validate="'required'"
							/>
							<span
								class="text-danger text-sm"
								v-show="errors.has('item-email')">
								{{ errors.first('item-email') }}
							</span>
						</div>
					</div> -->
          <!-- company -->
          <!-- <div class="vx-col sm:w-full w-full mb-2">
						<div class="mt-8">
							<vs-input v-filter-input
								class="w-full"
								name="item-company"
                :label="$t('Company')"
								:label-placeholder="$t('Company')"
								v-model="dataCompany"
								v-validate="'required'"
							/>
							<span
								class="text-danger text-sm"
								v-show="errors.has('item-company')">
								{{ errors.first('item-company') }}
							</span>
						</div>
					</div> -->
					<!-- name -->
					<div class="vx-col sm:w-full w-full mb-2">
						<div class="mt-2">
							<vs-input v-filter-input
								class="w-full"
								name="name"
                :label="$t('Name')"
								:label-placeholder="$t('Name')"
								v-model="dataName"
								v-validate="'required'"
							/>
							<span
								class="text-danger text-sm">
								{{ $t(errors.first('name')) }}
							</span>
						</div>
					</div>
				<!-- Phone -->
				<!-- <div class="mb-5">
					<label class="vs-select--label mb-1 text-black">
						{{ $t('Phone Number') }}
					</label>
					<VuePhoneNumberInput
					:translations="phoneTranslations"
					class="input-number"
					:default-country-code="'NO'"
					:preferred-countries="['NO', 'US', 'GB']"
					@update="onUpdate"
					v-model="dataPhone" />
				</div> -->
				<!-- Language -->
				<!-- <vs-select
					class="mt-3 w-full"
					name="item-gender"
					:label="$t('Language')"
          disabled
					v-model="dataLanguage"
					@change="updateLocale">
					<vs-select-item
						v-for="item in languages"
						:key="item.value"
						:value="item.value"
						:text="item.text"
					/>
				</vs-select> -->
			</div>
		</div>

		<!-- Footer -->
		<div
			class="flex flex-wrap items-center p-6"
			slot="footer">
			<vs-button
				class="mr-6"
				:class="{ 'is-loading': isSaving }"
				:disabled="!isFormValid"
				@click="submitData">
				{{ $t('Submit') }}
			</vs-button>
			<vs-button
				type="border"
				color="danger"
				@click="isSidebarActiveLocal = false">
				{{ $t('Cancel') }}
			</vs-button>
		</div>
    <modal
      class="modal-profie"
      size="medium"
      :hide-footer="true"
      :hasScroll="true"
      :Modal-close="true"
      :isEnableKeyUp="false"
      @close="closeModalCropper"
      v-if="isShowModalCropper">
      <div slot="header">
        <h5 class="modal-title">{{ $t('Set Profile Picture') }}</h5>
      </div>
      <div slot="body">
        <div>
          <ImageCropper :imageCropper="imageCropper" @cropImage="cropImage" @close="close"/>
        </div>
      </div>
    </modal>
	</vs-prompt>
</template>

<script>
/* eslint-disable camelcase */
import Modal from '@/components/Modal.vue';
import fileApi from '@/api/file';
// import VuePhoneNumberInput from 'vue-phone-number-input';
import ImageCropper from '@/components/ImageCropper.vue';
import {
  getAxiosErrorMessage, duplicateVar, getFirstCharacter, generateColorByAlphabet,
} from '../lib/helper';
import authApi from '../api/auth';

export default {
  name: 'ProfileSidebar',
  props: {
    isSidebarActive: {
      type: Boolean,
      default: true,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    eventId: {
      type: String,
    },
  },
  components: {
    // VuePhoneNumberInput,
    ImageCropper,
    Modal,
  },
  data() {
    return {
      dataId: null,
      dataEmail: '',
      dataName: '',
      dataPhone: '',
      // dataCompany: '',
      dataImg: null,
      dataLanguage: 'no',
      languages: [
        { value: 'no', text: 'Norsk' },
        { value: 'en', text: 'English' },
      ],

      isSaving: false,
      isUploading: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      icon: null,
      resultsPhone: {},
      phoneTranslations: {
        countrySelectorLabel: this.$t('Code Country'),
        countrySelectorError: this.$t('Choose one country'),
        phoneNumberLabel: this.$t('Phone Number'),
        example: this.$t('Example:'),
      },
      isShowModalCropper: false,
      isShowAvatar: true,
      imageCropper: null,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.setData();
      }
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          this.$validator.reset();
          this.initValues();
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
				&& !this.isUploading
      );
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    params() {
      const params = {
        id: this.dataId,
        event_id: this.dataEventId,
        email: this.dataEmail,
        name: this.dataName,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
        language: this.dataLanguage,
        avatar: this.dataImg,
        // company: this.dataCompany,
      };
      return params;
    },
  },
  methods: {
    onUpdate(payload) {
      this.resultsPhone = payload;
    },
    printTitlePrompt() {
      return `${this.$t('Edit Profile')}`;
    },
    setData() {
      const {
        id,
        // profile,
        email,
        language,
        mobile_phone,
        avatar_thumbnail,
        name,
      } = duplicateVar(this.data);
      const picture = avatar_thumbnail;
      this.dataId = id;
      this.dataEmail = email;
      this.dataName = name;
      this.dataPhone = mobile_phone;
      this.dataLanguage = language;
      // this.dataCompany = company;
      this.dataImg = picture;
      this.initValues();
    },
    initValues() {
      if (this.data.id) return;
      this.dataId = null;
      this.dataName = '';
      this.dataEmail = '';
      this.dataPhone = '';
      // this.dataCompany = '';
      this.dataImg = null;
      this.dataLanguage = 'no';
    },
    submitData() {
      this.isSaving = true;
      const title = this.$t('Profile');
      const params = this.params;
      const callback = (response) => {
        const user = response.data;
        this.$store.dispatch('setUser', user);
        this.$emit('updateUser', user);
        const message = response.message;
        this.$emit('add', user);
        this.$emit('closeSidebar');
        this.initValues();
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSaving = false;
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title,
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSaving = false;
      };
      authApi.update(this.dataId, params, callback, errorCallback);
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.isUploading = true;
        const file = input.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataImg = url;
          this.isUploading = false;
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
        };

        fileApi.upload(formData, callback, errorCallback);
      }
    },
    updateLocale(locale) {
      this.$i18n.locale = locale;
      document.querySelector('html').setAttribute('lang', locale);
      this.$store.dispatch('setLocale', locale);
    },
    generateInitial(dataName) {
      const words = dataName ? dataName.split(' ') : 'A';
      let initial = words[0][0] !== undefined ? words[0][0] : 'A';
      if (words.length > 1) {
        const firstCharacter = words[0][0] !== undefined ? words[0][0] : '';
        // const secondCharacter = words[1][0] !== undefined ? words[1][0] : '';
        initial = `${firstCharacter}`;
      }
      return initial;
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
    selectImage(input) {
      this.imageCropper = input;
      this.isShowModalCropper = true;
    },
    cropImage(image) {
      this.$vs.loading();
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
      if (image) {
        this.isUploading = true;
        const file = image;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(file);

        // Upload Image
        const formData = new FormData();
        formData.append('file', file);

        const callback = (response) => {
          const url = response.data;
          this.dataImg = url;
          this.isShowAvatar = false;
          setTimeout(() => {
            this.isShowAvatar = true;
          }, 500);
          this.isUploading = false;
          this.$vs.loading.close();
          this.imageCropper = null;
          this.$refs.updateImgProfile.value = null;
          this.isShowModalCropper = false;
        };

        const errorCallback = (e) => {
          const message = getAxiosErrorMessage(e);
          this.$vs.notify({
            title: this.$t('Upload Image'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
          this.isUploading = false;
          this.$vs.loading.close();
          this.imageCropper = null;
          this.$refs.updateImgProfile.value = null;
          this.isShowModalCropper = false;
        };
        fileApi.upload(formData, callback, errorCallback);
      }
    },
    deleteAvatar() {
      this.dataImg = '';
      this.isShowAvatar = false;
      setTimeout(() => {
        this.isShowAvatar = true;
      }, 500);
      this.isUploading = false;
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
    close() {
      this.imageCropper = null;
      this.$refs.updateImgProfile.value = null;
      this.isShowModalCropper = false;
    },
  },
};
</script>
<style lang="scss">
[dir] .dialog-broadcast.con-vs-dialog .vs-dialog .vs-dialog-text {
    padding: 1rem;
}
[dir] .dialog-broadcast.con-vs-dialog .vs-dialog {
	max-width: 550px;
    min-width: 550px;
    width: 70%;
}
.button-upload{
	position: absolute;
	bottom: 0;
	right: 0;
}
.button-upload-avatar{
	position: absolute;
	bottom: 0;
	right: 0;
}
.upload-profile .vs-avatar--con-img img {
    width: 150px;
	height:150px;
	object-fit:cover;
}
.upload-profile .vs-avatar--text {
	font-size: 6em;
}
.thumbnail-profile-sidebar {
  margin-bottom: 20px;
}
</style>
