<template>
	<!-- Sidebar -->
	<div id="profile-page">
		<profile-sidebar
			:isSidebarActive="addNewDataSidebarProfile"
			:data="sidebarDataProfile"
			@closeSidebar="toggleDataSidebarProfile"
			@add="insert"
		/>

		<change-password-sidebar
			:isSidebarActive="addNewDataSidebarPassword"
			:data="sidebarDataPassword"
			@closeSidebar="toggleDataSidebarPassword"
			@add="insert"
		/>
			<!-- Details -->
				<vs-row vs-justify="center" >
					<vs-col type="flex" vs-justify="center" vs-align="center" class="xs:w-1/1 sm:w-1/1 lg:w-1/1 relative">
					<div class="relative profile-wrapper">
						<div class="title-profile-user profile-wrapper">{{$t('Profile')}}</div>
						<div class="flex items-center head-card-profile relative">
                <vs-avatar  v-if="!dataImg" size="60px" class="mt-4 ml-6 text-4xl"  :color="getColor(dataName)" :text="getInitial(dataName)" />
                <vs-avatar v-if="dataImg" size="60px" class="mt-4 ml-6 text-4xl" :src="dataImg" />
                <div><h1 class="ml-4 text-white">{{ user.name }}</h1></div>
						</div>
						<div class="flex profile-actions">
						<vx-tooltip text="Edit Profile" position="bottom" delay=".3s">
							<vs-button class="profile-btn" icon-pack="feather" radius icon="icon-edit-2" @click="updateProfile"></vs-button>
						</vx-tooltip>
						<vx-tooltip text="Change Password" position="bottom" delay=".3s">
							<vs-button
								icon-pack="feather"
								radius
								type="filled"
								icon="icon-lock"
								class="ml-2 lg:ml-4 profile-btn"
								@click="changePassword"
							></vs-button>
						</vx-tooltip>
						</div>
					</div>
					<!-- ABOUT CARD -->
					<vx-card class=" mt-0 content-profile  profile-wrapper">
						<div class="mt-5 mb-7">
							<h6 class="mb-5">{{ $t('Email') }}:</h6>
							<p>{{ user.email }}</p>
						</div>
						<!-- <div class="mt-5 pb-3">
							<h6 class="mb-2">{{ $t('Language') }}:</h6>
							<p class="flex align-middle">
								<img
									class="profile-lang-img mr-2"
									:src="i18nLocaleImg"
									:alt="languageLabel">
								<span>{{ languageLabel }}</span>
							</p>
						</div> -->
					</vx-card>
				</vs-col>
			</vs-row>
		</div>
</template>

<script>
/* eslint-disable camelcase */
import ProfileSidebar from '@/components/ProfileSidebar.vue';
import ChangePasswordSidebar from '@/components/ChangePasswordSidebar.vue';
import {
  getAxiosErrorMessage, duplicateVar, getFirstCharacter, generateColorByAlphabet,
} from '../../lib/helper';
import authApi from '../../api/auth';

export default {
  name: 'Profile',
  components: {
    ProfileSidebar,
    ChangePasswordSidebar,
  },
  data() {
    return {
      addNewDataSidebarProfile: false,
      sidebarDataProfile: {},
      addNewDataSidebarPassword: false,
      sidebarDataPassword: {},
      selectedItem: null,
      user_info: {
        // eslint-disable-next-line global-require
        cover_img: require('@/assets/images/backgrounds/night_sky.jpg'),
      },
      user: [],
      valid: true,
      background: './img/backgrounds/login-bg.png',
      old_password: '',
      new_password: '',
      confirm_password: '',
      password: '',
      showPassword: false,
      showPassword2: false,
      isSaving: false,
      dataName: '',
      dataRole: '',
      dataImg: null,
      isFetching: false,
      languages: [
        { text: 'Indonesia', value: 'id' },
        { text: 'English', value: 'en' },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  watch: {},
  computed: {
    isFormValid() {
      return (
        !this.errors.any()
				&& this.old_password !== ''
				&& this.new_password !== ''
				&& this.confirm_password !== ''
      );
    },
    i18nLocaleImg() {
      // eslint-disable-next-line
			return require(`@/assets/images/flags/${this.$i18n.locale}.png`);
    },
    languageLabel() {
      let label = '';
      const locale = this.$i18n.locale;
      if (locale === 'no') {
        label = 'Norsk';
      } else if (locale === 'en') {
        label = 'English';
      }
      return label;
    },
  },
  methods: {
    fetch() {
      this.$vs.loading();
      this.isFetching = true;
      const callback = (response) => {
        this.user = response.data;
        this.dataImg = this.user.avatar_thumbnail;
        this.dataName = this.user.name;
        this.dataRole = this.user.role;
        this.isFetching = false;
        this.$vs.loading.close();
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: this.$t('User'),
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isFetching = false;
        this.$vs.loading.close();
      };
      authApi.getProfile(callback, errorCallback);
    },
    updateProfile() {
      this.selectedItem = duplicateVar(this.user);
      this.sidebarDataProfile = duplicateVar(this.user);
      this.toggleDataSidebarProfile(true);
    },
    toggleDataSidebarProfile(val = false) {
      this.addNewDataSidebarProfile = val;
    },
    insert() {
      this.fetch();
    },
    changePassword() {
      this.selectedItem = duplicateVar(this.user);
      this.sidebarDataProfile = duplicateVar(this.user);
      this.toggleDataSidebarPassword(true);
    },
    toggleDataSidebarPassword(val = false) {
      this.addNewDataSidebarPassword = val;
    },
    generateInitial(dataName) {
      const words = dataName ? dataName.split(' ') : 'AN';
      let initial = words[0][0] !== undefined ? words[0][0] : 'AN';
      if (words.length > 1) {
        const firstCharacter = words[0][0] !== undefined ? words[0][0] : '';
        // const secondCharacter = words[1][0] !== undefined ? words[1][0] : '';
        initial = `${firstCharacter}`;
      }
      return initial;
    },
    getInitial(str) {
      return getFirstCharacter(str);
    },
    getColor(str) {
      return generateColorByAlphabet(str);
    },
  },
  created() {
    this.fetch();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
.profile-image-view{
	margin-bottom: 4em;
}
.head-card-profile{
	width: 100%;
	height: 100px;
	background: #4991DF;
}
.content-profile{
	min-height: 470px;
}
.profile-actions {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	margin-right: 5em;
	margin-top: 11em;
}
.profile-btn.vs-button{
    border-radius: 1.5rem!important;
}
.preview-profile .vs-avatar--con-img img {
    width: 70px;
	height:70px;
	object-fit:cover;
}
.preview-profile .vs-avatar--text {
	font-size: 3.5em;
}
.title-profile-user{
	font-size: 46px;
	font-weight: 100;
	color:	#363636;
}
.profile-wrapper{
	margin-left: auto;
	margin-right: auto;
	max-width: 681px;
}
.thumbnail-profile {
	position: relative;
	min-height: 70px;
	min-width: 70px;
	max-width: 70px;
	max-height: 70px;
	text-align: center;
	padding-top: 0.9em;
	padding-left: 0.8em;
	margin-right: 10px;
	margin-left: 10px;
	.initial-profile {
		font-size: 2.3em;
		padding: 12px;
		min-width: 70px;
		max-width: 70px;
		margin: 0 auto;
		border-radius: 50%;
		background-color: #2BC1FF;
		color: #fff;
	}
}
</style>
